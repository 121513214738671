import get from 'lodash/get';
import find from 'lodash/find';
import { createAction, createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import { loadLoggedInAccount } from 'modules/accounts';
import { Cognito } from './services';
import {
  USER_LOADED,
  PASSWORD_REQUIRED,
  PASSWORD_NOT_REQUIRED,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  completeNewPassword as apiCompleteNewPassword,
  login as apiLogin,
  logout as apiLogout,
  signUp as apiSignUp,
  requestProducerApproval as apiProducerApproval,
  verifyEmail as apiVerifyEmail,
  resetPassword as apiResetPassword,
  forgotPassword as apiForgotPasword,
  changePassword as apiChangePassword,
  resendVerificationEmail as apiResendVerificationEmail,
  uploadApplicationAudioClip as apiUploadAudioClip,
} from './api';

const INITIAL_STATE = {
  authenticated: false,
  passwordResetRequired: false,
  cognitoUser: null,
  loading: false,
  loginError: false,
  signupError: false,
  signupSuccess: false,
  pendingVerification: false,
  verificationError: false,
  verificationSuccess: false,
  resetPasswordError: false,
  resetPasswordSuccess: false,
  changePasswordError: false,
  changePasswordSuccess: false,
  forgotPasswordSuccess: false,
  forgotPasswordError: false,
  resendVerificationEmailSuccess: false,
  resendVerificationEmailError: false,
  producerApprovalError: false,
  producerApprovalSuccess: false,
};

export const login = apiLogin;
export const logout = createThunk(apiLogout, 'logout', 'auth');
export const signUp = createThunk(apiSignUp, 'signUp', 'auth');
export const producerApproving = createThunk(
  apiProducerApproval,
  'producerApproving',
  'auth',
);
export const clearSignUpStatus = createAction('auth/clear-sign-up-status');
export const verifyEmail = createThunk(apiVerifyEmail, 'verifyEmail', 'auth');
export const resetPassword = createThunk(
  apiResetPassword,
  'resetPassword',
  'auth',
);
export const clearResetPasswordStatus = createAction(
  'auth/clear-reset-password-status',
);
export const completeNewPassword = createThunk(
  apiCompleteNewPassword,
  'completeNewPassword',
  'auth',
);
export const forgotPassword = createThunk(
  apiForgotPasword,
  'forgotPassword',
  'auth',
);
export const clearForgotPasswordStatus = createAction(
  'auth/clear-forgot-password-status',
);
export const changePassword = createThunk(
  apiChangePassword,
  'changePassword',
  'auth',
);
export const clearChangePasswordStatus = createAction(
  'auth/clear-change-password-status',
);
export const resendVerificationEmail = createThunk(
  apiResendVerificationEmail,
  'resendVerificationEmail',
  'auth',
);
export const clearResendVerificationEmailStatus = createAction(
  'auth/clear-resend-verification-email-status',
);

export const uploadApprovalAudioClip = createThunk(
  apiUploadAudioClip,
  'uploadApprovalAudioClip',
  'auth',
);

export const reducer = createReducer(INITIAL_STATE, {
  [PASSWORD_REQUIRED]: state => {
    state.passwordResetRequired = true;
  },
  [PASSWORD_NOT_REQUIRED]: state => {
    state.passwordResetRequired = false;
  },
  [USER_LOADED]: (state, action) => {
    state.cognitoUser = action.payload;
  },
  [LOGIN_SUCCESS]: (state, action) => {
    state.loading = false;
    state.authenticated = true;
    state.pendingVerification = false;
  },
  [LOGIN_ERROR]: (state, action) => {
    const error = get(action, 'error');
    const errorCode = get(error, 'code');

    state.loading = false;
    state.authenticated = false;

    if (errorCode === Cognito.CODES.USER_NOT_CONFIRMED) {
      state.pendingVerification = true;
      state.loginError = false;
      return;
    }

    state.pendingVerification = false;
    state.loginError = Cognito.getCognitoErrorMessage(error);
  },
  [completeNewPassword.type.success]: state => {
    state.completeNewPasswordSuccess = true;
    state.completeNewPasswordError = false;
  },
  [completeNewPassword.type.error]: (state, action) => {
    const error = get(action, 'error');

    state.completeNewPasswordSuccess = false;
    state.completeNewPasswordError = Cognito.getCognitoErrorMessage(error);
  },
  [loadLoggedInAccount.type.success]: state => {
    state.loginError = false;
  },
  [signUp.type.success]: state => {
    state.loading = false;
    state.authenticated = false;
    state.signupError = false;
    state.signupSuccess = true;
    state.pendingVerification = true;
  },
  [signUp.type.error]: (state, action) => {
    const error = get(action, 'error');

    state.signupSuccess = false;
    state.signupError = Cognito.getCognitoErrorMessage(error, true);
  },
  [clearSignUpStatus]: state => {
    state.signupSuccess = false;
    state.signupError = false;
  },

  [producerApproving.type.request]: state => {
    state.loading = true;
  },
  [producerApproving.type.success]: state => {
    state.producerApprovalSuccess = true;
    state.loading = false;
  },
  [producerApproving.type.error]: (state, action) => {
    state.producerApprovalError = get(action, 'error');
    state.loading = false;
  },

  [resetPassword.type.success]: state => {
    state.resetPasswordError = false;
    state.resetPasswordSuccess = true;
  },
  [resetPassword.type.error]: (state, action) => {
    const error = get(action, 'error');

    state.resetPasswordSuccess = false;
    state.resetPasswordError = Cognito.getCognitoErrorMessage(error);
  },
  [clearResetPasswordStatus]: state => {
    state.resetPasswordSuccess = false;
    state.resetPasswordError = false;
  },
  [forgotPassword.type.success]: state => {
    state.forgotPasswordError = false;
    state.forgotPasswordSuccess = true;
  },
  [forgotPassword.type.error]: (state, action) => {
    const error = get(action, 'error');

    state.forgotPasswordSuccess = false;
    state.forgotPasswordError = Cognito.getCognitoErrorMessage(error);
  },
  [clearForgotPasswordStatus]: state => {
    state.forgotPasswordSuccess = false;
    state.forgotPasswordError = false;
  },
  [resendVerificationEmail.type.success]: state => {
    state.resendVerificationEmailError = false;
    state.resendVerificationEmailSuccess = true;
  },
  [resendVerificationEmail.type.error]: (state, action) => {
    const error = get(action, 'error');

    state.resendVerificationEmailSuccess = false;
    state.resendVerificationEmailError = Cognito.getCognitoErrorMessage(error);
  },
  [clearResendVerificationEmailStatus]: (state, action) => {
    state.resendVerificationEmailSuccess = false;
    state.resendVerificationEmailError = false;
  },
  [clearResendVerificationEmailStatus]: state => {
    state.resendVerificationEmailSuccess = false;
    state.resendVerificationEmailError = false;
  },
  [changePassword.type.success]: state => {
    state.changePasswordError = false;
    state.changePasswordSuccess = true;
  },
  [changePassword.type.error]: (state, action) => {
    const error = get(action, 'error');
    state.changePasswordSuccess = false;
    state.changePasswordError = Cognito.getCognitoErrorMessage(error);
  },
  [clearChangePasswordStatus]: state => {
    state.changePasswordSuccess = false;
    state.changePasswordError = false;
  },
  [verifyEmail.type.success]: state => {
    state.verificationError = false;
    state.verificationSuccess = true;
    state.pendingVerification = false;
  },
  [verifyEmail.type.error]: (state, action) => {
    const error = get(action, 'error');
    if (error.code !== Cognito.CODES.NOT_AUTHORIZED) {
      state.verificationSuccess = false;
      state.verificationError = Cognito.getCognitoErrorMessage(error);
    } else {
      state.verificationError = false;
      state.verificationSuccess = true;
      state.pendingVerification = false;
    }
  },
  [uploadApprovalAudioClip.type.request]: state => {
    state.loading = true;
  },
  [uploadApprovalAudioClip.type.error]: state => {
    state.producerApprovalError = 'Service unavailable please try again later';
    state.loading = false;
  },
});

export const getIsAuthenticated = createSelector(['auth.authenticated']);

export const getLoginError = createSelector(['auth.loginError']);
export const getSignupError = createSelector(['auth.signupError']);
export const getSignupSuccess = createSelector(['auth.signupSuccess']);
export const getProducerApprovalError = createSelector([
  'auth.producerApprovalError',
]);
export const getProducerApprovalSuccess = createSelector([
  'auth.producerApprovalSuccess',
]);
export const getChangePasswordError = createSelector([
  'auth.changePasswordError',
]);
export const getChangePasswordSuccess = createSelector([
  'auth.changePasswordSuccess',
]);
export const getResetPasswordError = createSelector([
  'auth.resetPasswordError',
]);
export const getResetPasswordSuccess = createSelector([
  'auth.resetPasswordSuccess',
]);
export const getCompleteNewPasswordError = createSelector([
  'auth.completeNewPasswordError',
]);
export const getCompleteNewPasswordSuccess = createSelector([
  'auth.completeNewPasswordSuccess',
]);
export const getForgotPasswordError = createSelector([
  'auth.forgotPasswordError',
]);
export const getResendVerificationEmailSuccess = createSelector([
  'auth.resendVerificationEmailSuccess',
]);
export const getResendVerificationEmailError = createSelector([
  'auth.resendVerificationEmailError',
]);
export const getForgotPasswordSuccess = createSelector([
  'auth.forgotPasswordSuccess',
]);
export const getVerificationError = createSelector(['auth.verificationError']);
export const getVerificationSuccess = createSelector([
  'auth.verificationSuccess',
]);
export const getPendingVerification = createSelector([
  'auth.pendingVerification',
]);
export const getPasswordResetRequired = createSelector([
  'auth.passwordResetRequired',
]);
export const getCognitoUser = createSelector(['auth.cognitoUser']);
export const getCognitoAccessToken = createSelector([
  'auth.cognitoUser.signInUserSession.accessToken.jwtToken',
]);
