import { useEffect, useCallback } from 'react';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

function  AhabReCaptchaComponent(props) {

  const { executeRecaptcha } = useGoogleReCaptcha();
  
  // Create an event handler so you can call the verification on button click event or form submit
  let recaptchaToken = null;
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    
    await executeRecaptcha('talentSignup')
    .then( token => {
      recaptchaToken = token;

      // send the recaptchaToken up to this component's parent component.
      props.func(recaptchaToken);
      //console.log(recaptchaToken, "TOKEN:");
    })
    .catch(error => console.error(error));    
    }, [executeRecaptcha]);
  
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    
    //return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
    return null;
};
export default AhabReCaptchaComponent;